const contactMe = [
  {
    title: "Email",
    description: "Let's get in touch.",
    link: "mailto:sreeja.vallamulla@gmail.com",
    icon: "text-xl fa-solid fa-envelope",
  },
  {
    title: "LinkedIn",
    description: "Let's connect.",
    link: "https://www.linkedin.com/in/sreeja-vallamulla",
    icon: "text-xl fa-brands fa-linkedin",
  },
  {
    title: "GitHub",
    description: "Check out my repos.",
    link: "https://github.com/sreeja-vallamulla",
    icon: "text-xl fa-brands fa-github",
  },
];

export default contactMe;
