import { AnimatePresence } from 'framer-motion';
import Hero from './components/hero/index.js';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import GoToTheTop from './components/GoToTheTop';
import Skills from './components/Skills';
import ContactMe from './components/ContactMe';
import NavBar from './components/navbar/index.js';

function App() {
  return (
    <>
      <NavBar />
      <AnimatePresence>
        <Hero />
        <AboutMe />
        <Skills />
        <Projects />
        <ContactMe />
        <GoToTheTop />
      </AnimatePresence>
    </>
  );
}

export default App;
