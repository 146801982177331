import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { handleScroll } from "../../utils/handleScroll";
import Profile from "../../img/golden_gate_pic.png";
import { motion } from 'framer-motion';

const Hero = () => {
  const texts = ["Software Engineer", "Full Stack Developer", "Quick Learner"];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
    <div className="overflow-hidden bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="container px-4 mx-auto md:max-w-full lg:max-w-screen-xl md:px-24">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-10">
          <div className="flex-1 text-center lg:text-left">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              <span style={{ color: '#274C77' }}>Hi there, I'm Sreeja</span>
            </h2>
            <br />
            <p className="mt-3 text-base text-gray-800 md:text-lg">
              <Typewriter
                words={texts}
                loop={0}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1500}
              />
            </p>
            <div className="mt-6">
              <a
                href="#about-me"
                onClick={handleScroll}
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#274C77] hover:bg-[#1a365d] focus:shadow-outline focus:outline-none"
              >
                Learn More About Me
              </a>
            </div>
          </div>
          <div class=" overflow-hidden flex items-center justify-center">
            <div class="max-w-xs lg:max-w-xl w-full h-auto overflow-hidden flex items-center justify-center">
              <img
                class="object-cover w-full h-full rounded-full shadow-lg select-none pointer-events-none"
                src={Profile}
                alt="Sreeja"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </motion.div>

  );
};

export default Hero;