import VedaAIVerse from "../img/vedaAIverse_img.png";
import PuzzleGame from "../img/Puzzle_Game_image.png";

const projects = [
  {
    title: "Cognitive Skill Games Platform",
    subTitle: "Gaming web app",
    description:
      "The Cognitive Skill Games Platform is an educational web application that enhances cognitive skills through gamified learning experiences.",
    image: PuzzleGame,
    technologies: [
      "ReactJS",
      "Redux",
      "TailwindCSS",
      "JavaScript",
      "TypeScript",
      "Vite",
      "Firebase",
    ],
    github: "https://github.com/sreeja-vallamulla/cognitive-skills-games-platform",
  },
  {
    title: "VedaAIVerse",
    subTitle: "Ed-Tech platform",
    description:
      "Vedaaiverse is a personalized learning platform designed to enhance educational experiences through a customized interface.",
    image: VedaAIVerse,
    technologies: [
      "ReactJS",
      "PostgreSQL",
      "NodeJS",
      "Express",
      "JavaScript",
      "Auth0",
      "DigitalOcean",
      "GenAI",
      "RESTAPI",
      "Audio Processing",
      "Web Sockets",
    ],
    live: "https://www.vedaaiverse.com",
  },
];

export default projects;