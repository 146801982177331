import projects from "../../utils/projectsList";
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

const Projects = () => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
    <div id="projects" className="bg-gray-900 text-white py-16">
      <div className="container px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Projects</h2>
          <p className="text-gray-400 max-w-xl mx-auto">
            Here's a showcase of my latest work.
          </p>
        </div>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-2 items-start p-6">
          {projects.map((project, index) => (
            <a
              key={index}
              href={project.live ? project.live : project.github}
              target="_blank"
              rel="noreferrer"
              className="block bg-gray-800 rounded-lg shadow-lg overflow-hidden transform hover:-translate-y-2 transition duration-300 ease-in-out"
            >
              {!project.live && (
                <img
                  src={project.image}
                  alt={project.title}
                  className="object-cover w-full max-h-64 rounded-t-lg"
                />
              )}
              {project.live && (
                <iframe
                  src={project.live}
                  title={project.title}
                  className="w-full h-64 border-0 rounded-lg"
                  loading="lazy"
                ></iframe>
              )}
              <div className="p-5">
                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                <h4 className="text-md text-gray-500 mb-3">{project.subTitle}</h4>
                <p className="text-gray-400 text-sm">
                  {project.description}
                </p>
                <ul className="flex flex-wrap mt-4 mb-4">
                  {project.technologies.map((tech, idx) => (
                    <li key={idx} className="mr-2 mb-2 bg-gray-700 text-xs font-medium rounded-full px-3 py-1">
                      {tech}
                    </li>
                  ))}
                </ul>
              </div>
            </a>
          ))}
        </div>
      </div>
      </div>
    </motion.div>
  );
};

export default Projects;