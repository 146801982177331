import Picture from "../../img/profile_picture.png";
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';


const AboutMe = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
    <div id="about-me" className="relative bg-gray-900 text-white min-h-screen flex items-center justify-center">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center opacity-10" style={{ backgroundImage: `url(${Picture})` }}></div>
      <div className="relative z-10 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto lg:max-w-4xl md:mb-12 text-center">
          <h2 className="text-4xl font-bold leading-tight tracking-tight text-gray-100 sm:text-5xl md:mx-auto">
            About Me
          </h2>
        </div>
        <div className="flex flex-wrap justify-center text-left">
          <div className="w-full lg:w-5/12 p-4 flex flex-col justify-between">
            <img src={Picture} alt="Sreeja" className="rounded-lg border border-gray-800 shadow-xl mb-4 lg:max-w-md" />
          </div>
          <div className="w-full lg:w-7/12 p-8 pl-14 pt-14">
            <p className="text-lg text-gray-300 leading-relaxed md:text-xl">
              Hello! I'm <strong>Sreeja Vallamulla</strong>, a passionate Full Stack Developer with a Master’s degree in Information Technology from the University of Cincinnati. I specialize in creating dynamic, high-performance web and mobile applications that enhance user experience and drive business success.
            </p>
            <p className="mt-4 text-lg text-gray-300 leading-relaxed md:text-xl">
              With robust experience at prestigious organizations like Accenture and transformative projects at the University of Cincinnati, I have honed my skills across diverse programming languages and technologies.
            </p>
          </div>
        </div>
      </div>
      </div>
    </motion.div>

  );
};

export default AboutMe;