import skills from "../../utils/skills";
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

const Skills = () => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
    <section
      id="skills"
      className="min-h-screen flex flex-col justify-center items-center py-20 bg-white"
    >
      <div className="text-center mb-16">
        <motion.h2 
          className="text-4xl font-bold text-gray-900 mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          My Skills
        </motion.h2>
        <motion.p 
          className="text-lg text-gray-600"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          A showcase of my expertise and capabilities
        </motion.p>
      </div>
      <div className="grid gap-12 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 px-4 max-w-7xl w-full">
        {skills.map((skill, index) => (
          <motion.div 
            key={index} 
            className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-md hover:scale-105 group hover:shadow-xl"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-blue-accent-400 group-hover:scale-x-100" />
            <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-blue-accent-400 group-hover:scale-y-100" />
            <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-blue-accent-400 group-hover:scale-x-100" />
            <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-blue-accent-400 group-hover:scale-y-100" />
            <div className="relative p-8 bg-white rounded-sm">
              <div className="flex items-center justify-center w-24 h-24 mb-6 mx-auto bg-gray-200 text-gray-900 rounded-full">
                <i className={`${skill.icon} text-4xl`} aria-hidden="true"></i>
              </div>
              <h5 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
                {skill.title}
              </h5>
              <ul className="space-y-2 text-center">
                {skill.skills.map((item, idx) => (
                  <li key={idx} className="text-sm text-gray-700">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
      </section>
    </motion.div>
  );
};

export default Skills;